<template>
  <div class="aiSystem">
    <div class="titleLine">
      <div class="lineTwo">
        <img src="../../assets/newImgs/home/leftsjx.png" alt="" />
        <div>非接触式智慧云餐厅</div>
        <img src="../../assets/newImgs/home/rightsjx.png" alt="" />
      </div>
      <div class="lineThree">smart inspection Solution</div>
    </div>
    <div class="mainCenter">
      <div class="boxEver">
        <div class="boxImg">
          <el-popover placement="right" trigger="hover">
            <div class="boxSign">
              <div class="sign-item1">自助存/取餐系统</div>
              <div class="sign-item2">order pickup solution</div>
              <div class="sign-item3">非接触式取餐减少等待，快捷高效</div>
              <div class="sign-item3">菜品恒温保存，提高用餐人员满意度</div>
            </div>
            <img
              src="../../assets/newImgs/nProductCenter/zizhu.png"
              slot="reference"
            />
          </el-popover>
        </div>
        <div class="boxTitle">自助存取餐系统</div>
        <div class="boxTxt">order pickup solution</div>
      </div>
      <div class="boxEver">
         <div class="boxImg">
        <el-popover placement="left" trigger="hover">
       
          <div class="boxSign">
              <div class="sign-item1">预约订单配餐系统</div>
              <div class="sign-item2">meal preparation system</div>
              <div class="sign-item3">套餐预约，节约成本不浪费</div>
              <div class="sign-item3">准时配送无需等待，提升用餐人员满意度</div>
            </div>
          <img src="../../assets/newImgs/nProductCenter/yuyue.png" slot="reference"/>
            </el-popover>
        </div>
      
        <div class="boxTitle">预约订单配餐系统</div>
        <div class="boxTxt">meal preparation system</div>
      </div>
      <div class="boxEver">
        
        <div class="boxImg">
           <el-popover placement="right" trigger="hover">
             <div class="boxSign">
              <div class="sign-item1">后台管理系统</div>
              <div class="sign-item2">Backstage Management System</div>
              <div class="sign-item3">自动采集数据，综合信息处理</div>
              <div class="sign-item3">快捷搜索简单易用，信息数据一键导出</div>
            </div>
          <img src="../../assets/newImgs/nProductCenter/houtai.png" slot="reference"/>
           </el-popover>
        </div>
        
      
        <div class="boxTitle">后台管理系统</div>
        <div class="boxTxt">
          Backstage Management<br />
          System
        </div>
      </div>
      <div class="boxEver">
        <div class="boxImg">
           <el-popover placement="left" trigger="hover">
             <div class="boxSign">
              <div class="sign-item1">前端点餐系统</div>
              <div class="sign-item2">ordering APP</div>
              <div class="sign-item3">餐前预约，准时送达 0 等待</div>
              <div class="sign-item3">微定制餐盘，个性化服务，柔性健康管理</div>
            </div>
        
            <img src="../../assets/newImgs/nProductCenter/diancan.png" slot="reference"/>
           </el-popover>
        
        </div>

        <div class="boxTitle">前端点餐系统</div>
        <div class="boxTxt">ordering APP</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cloudRestaurant",
};
</script>

<style lang="scss" scoped>
.aiSystem {
  width: 1920px;
  height: 1200px;
  background: white;
  padding-top: 90px;
  box-sizing: border-box;
}
.titleLine {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.lineTwo {
  display: flex;
  font-size: 36px;
  color: #333333;
  align-items: center;
  img {
    width: 45px;
  }
}
.lineThree {
  color: #666666;
  font-size: 20px;
  margin-top: 10px;
}

.mainCenter {
  position: relative;
  width: 900px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 80px;
  img:hover {
    transform: scale(1.5);
    transition: 0.5s;
  }
}
.boxEver {
  position: relative;
  width: 360px;
  margin-top: 50px;
}
.boxImg {
  width: 360px;
  height: 240px;
  border-radius: 8px;
  background: #a7c69c;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    //width: 275px;
    height: 160px;
    transition: 0.5s;
  }
}
.boxTitle {
  font-size: 20px;
  color: #333333;
  margin-top: 20px;
}
.boxTxt {
  font-size: 14px;
  color: #666666;
  margin-top: 10px;
}
.boxImgPhone {
  border-radius: 8px;
  background: #a7c69c;
  width: 360px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  .innerImgs {
    width: 275px;
    height: 160px;
    display: flex;
    justify-content: space-between;
  }
  img {
    width: 78px;
    height: 158px;
    transition: 0.5s;
  }
}
</style>
<style lang="scss">
.boxSign {
   margin: 10px;
  .sign-item1 {
    color: #598948;
    font-size: 20px;
    border-bottom: 1px solid #a7c69c;
    padding-bottom: 4px;
   
  }
  .sign-item2 {
    color: #a7c69c;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .sign-item3{
      margin: 10px 0;
    font-size: 12px;
  }
}
</style>